/* Add these classes in your CSS file */
.first\:w-full {
  width: 100%; /* Full width for the first element */
}

.w-half {
  width: calc(33.3333% - 1rem); /* Equal width for the rest, considering gap */
}

@media (min-width: 768px) {
  .sorted_with_unsorted:nth-child(2)::before {
    content: "Sorted";
    color: #60849f;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    position: absolute;
    top: 190px;
  }
}
