.render-dots {
  display: flex;
  justify-content: center;
  position: relative;
  top: 10px;
}

@media (max-width: 590px) {
  .render-dots {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%);
  }
}

@media (min-width: 640px) and (max-width: 845px) {
  .render-dots {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%);
  }
}

.display-btns {
  display: none;
}

@media (min-width: 640px) {
  .display-btns {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    top: 59%;
    gap: 8px;
    right: -25%;
    width: 110px;
    transform: translate(50%, 50%);
  }
}
