/* width */
.scrollbar-invisible {
  overflow-y: scroll;
}

.scrollbar-invisible::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.scrollbar-invisible::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollbar-invisible::-webkit-scrollbar-thumb {
  background: #e2e3e6;
}

/* Handle on hover */
.scrollbar-invisible::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
